import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import { graphql } from 'gatsby'

export default function FeaturedBrands({ data }) {
    const { html, tableOfContents } = data.markdownRemark

    return (
        <Layout>
            <Head title="Featured Brands" />
            <div className="container">
                <section>
                    <div className="row text-muted shadow-lg">
                        <div className="col-lg-3 lh-lg dark-contrast-border p-2">
                            <div className="sticky-top">
                                <h1 className="brand-color fw-bold text-end" style={{ fontSize: "3.5rem" }}>featured brands.</h1>
                                <p className="fw-light text-end">
                                    brands to trust in.
                                </p>
                                <ul className="text-end page-nav">
                                    <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 dark-contrast fw-light p-3">
                            <small className="text-muted">We deal in best brands currently in the market.</small>
                            <div className="mt-5"></div>
                            <div className="mb-5">
                                <section id="about-us" className="page-content">
                                    <div dangerouslySetInnerHTML={{ __html: html }} className="lh-lg"/>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export const query = graphql`
query FeaturedBrandsQuery {
    markdownRemark(frontmatter: {slug: {eq: "featured-brands"}}) {
        html
        tableOfContents
        }
    }
`
